var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("发起人")]),_c('EmployeeSelector',{attrs:{"value":_vm.promoter.name ? [_vm.promoter] : []},on:{"change":(arr) => {
                              if (arr.length > 0) {
                                _vm.promoter = arr[0];
                              } else {
                                _vm.promoter = {};
                              }
                            }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.promoter.name)?_c('div',[_vm._v(_vm._s(_vm.promoter.name))]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("接收人")]),_c('EmployeeSelector',{attrs:{"value":_vm.recipient.name ? [_vm.recipient] : []},on:{"change":(arr) => {
                              if (arr.length > 0) {
                                _vm.recipient = arr[0];
                              } else {
                                _vm.recipient = {};
                              }
                            }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.recipient.name)?_c('div',[_vm._v(_vm._s(_vm.recipient.name))]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"协作内容","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'remark',
                { 
                  initialValue: _vm.detail.remark,
                  rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'remark',\n                { \n                  initialValue: detail.remark,\n                  rules: [{ required: true, message: '请输入！' }] },\n              ]"}],attrs:{"autoSize":{
                  minRows: 6,
              }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 3 },"wrapper-col":{ span: 19 },"label":"附件"}},[_c('Upload',{attrs:{"list":_vm.fileList},on:{"change":_vm.onChange}})],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.saveLoading}},[_vm._v(_vm._s(_vm.detail.id ? '重新发起审批' : '发起审批'))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }