<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class="required">发起人</span>
              <EmployeeSelector
                @change="(arr) => {
                                if (arr.length > 0) {
                                  promoter = arr[0];
                                } else {
                                  promoter = {};
                                }
                              }
                              "
                :value="promoter.name ? [promoter] : []"
              >
                <a-button block style="text-align: left">
                  <div v-if="promoter.name">{{ promoter.name }}</div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span slot="label" class="required">接收人</span>

              <EmployeeSelector
                @change="(arr) => {
                                if (arr.length > 0) {
                                  recipient = arr[0];
                                } else {
                                  recipient = {};
                                }
                              }
                              "
                :value="recipient.name ? [recipient] : []"
              >
                <a-button block style="text-align: left">
                  <div v-if="recipient.name">{{ recipient.name }}</div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="协作内容" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }">
              <a-textarea
                :autoSize="{
                    minRows: 6,
                }"
                v-decorator="[
                  'remark',
                  { 
                    initialValue: detail.remark,
                    rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" label="附件">
              <Upload :list="fileList" @change="onChange" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="footer center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button
              htmlType="submit"
              type="primary"
              :loading="saveLoading"
            >{{ detail.id ? '重新发起审批' : '发起审批' }}</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import EmployeeSelector from "@/components/employee-selector";
import Upload from "./components/upload.vue";

function add(data) {
  return request({
    url: "/office-service/safety/collaborate/add",
    method: "post",
    data
  });
}

function reAdd(data) {
  return request({
    url: "/office-service/safety/collaborate/reAdd",
    method: "post",
    data
  });
}

function fetchDetail(params) {
  return request({
    url: "/office-service/safety/collaborate/query",
    params
  });
}

export default {
  mixins: [watermark],

  components: {
    EmployeeSelector,
    Upload
  },

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      promoter: {},
      recipient: {},
      fileList: [],
      saveLoading: false
    };
  },

  mounted() {
    const { id } = this.$route.query;
    if (id) {
      fetchDetail({
        id
      }).then(res => {
        this.detail = res || {};

        this.promoter = {
          userId: res.applicantId,
          name: res.applicant,
          deptId: res.applicantDeptId,
          deptUniqueName: res.applicantDept
        };
        this.recipient = {
          userId: res.receiverId,
          name: res.receiver,
          deptId: res.receiverDeptId,
          deptUniqueName: res.receiverDept
        };

        if (typeof res.attachment === "string") {
          this.fileList = res.attachment.split(",").map(item => {
            return {
              completePath: item,
              name: this.$getFileName(item)
            };
          });
        }
      });
    }
  },

  methods: {
    onChange(list) {
      this.fileList = list;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log("values", values);
        if (!err) {
          if (!this.promoter.name) {
            this.$message.error("请选择发起人");
            return;
          }
          if (!this.recipient.name) {
            this.$message.error("请选择接收人");
            return;
          }

          this.saveLoading = true;

          let ask = add;

          if (this.detail.id) {
            ask = reAdd;
          }

          ask({
            ...this.detail,
            ...values,

            applicantId: this.promoter.userId,
            applicant: this.promoter.name,
            applicantDeptId: this.promoter.deptId,
            applicantDept: this.promoter.deptUniqueName,

            receiverId: this.recipient.userId,
            receiver: this.recipient.name,
            receiverDeptId: this.recipient.deptId,
            receiverDept: this.recipient.deptUniqueName,

            attachment: this.fileList.map(item => item.completePath).join(","),

            chargeType: "0"
          })
            .then(() => {
              this.$close(this.$route.path);
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    }
  }
};
</script>


<style lang="less" scoped>
.container {
  padding-top: 24px;
}
.footer {
  padding: 80px 0;
}
</style>